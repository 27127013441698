import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TemplateMessageObjectPager, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel, ChannelService, WaTemplateResponse } from 'lib-trend-core';
import { debounceTime, takeUntil } from 'rxjs';

export interface TemplateMessage {
  previewText: string;
  params: any[];
  imageFile: string;
}

@Component({
  selector: 'wa-template-select',
  templateUrl: './wa-template-select.component.html',
  styleUrls: ['./wa-template-select.component.scss']
})
export class WaTemplateSelectComponent extends AbstractComponent implements OnInit {
  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.listWaTemplates();
  }

  private _selectedTemplateId: string;
  @Input() set selectedTemplateId(id: string) {
    this._selectedTemplateId = id;
    if (id) {
      // Clear filters when template is selected
      this.searchControl.setValue('', { emitEvent: false });
      this.selectedCategory = '';
    }
    if (this.selectedChannel) {
      this.listWaTemplates();
    }
  }
  get selectedTemplateId(): string {
    return this._selectedTemplateId;
  }

  @Output() onSelectTemplate = new EventEmitter<Partial<WaTemplateResponse>>();

  selectedTemplate: Partial<WaTemplateResponse> | null = null;
  waTemplates: Partial<WaTemplateResponse>[] = [];
  allTemplates: Partial<WaTemplateResponse>[] = [];
  selectedChannel: Channel;
  form: FormGroup;
  searchControl: FormControl = new FormControl('');
  selectedCategory: string = '';

  categories = [
    { value: 'MARKETING', label: 'Marketing' },
    { value: 'UTILITY', label: 'Utilitário' },
    { value: 'AUTHENTICATION', label: 'Autenticação' },
    { value: 'SERVICE', label: 'Serviço' }
  ];

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public trendCloudAPIService: TrendCloudAPIService,
    public override formBuilder: FormBuilder,
  ) {
    super(injector);
    this.form = this.formBuilder.group({});
    this.setupSearchListener();
  }

  private setupSearchListener() {
    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value: string) => {
        this.filterTemplates(value);
      });
  }

  filterByCategory(category: string) {
    if (this.selectedTemplateId) return;
    this.selectedCategory = category;
    this.applyFilters();
  }

  filterTemplates(event: any) {
    if (this.selectedTemplateId) return;
    const value = typeof event === 'string' ? event : event?.target?.value || '';
    this.applyFilters(value);
  }

  private applyFilters(searchValue: string = this.searchControl.value) {
    let filteredTemplates = [...this.allTemplates];

    // Aplicar filtro de categoria
    if (this.selectedCategory) {
      filteredTemplates = filteredTemplates.filter(template =>
        template.category === this.selectedCategory
      );
    }

    // Aplicar filtro de busca
    if (searchValue?.trim()) {
      const search = searchValue.toLowerCase();
      filteredTemplates = filteredTemplates.filter(template =>
        template.name.toLowerCase().includes(search) ||
        template.category?.toLowerCase().includes(search) ||
        template.bodyComponent?.text?.toLowerCase().includes(search)
      );
    }

    this.waTemplates = filteredTemplates;
  }

  async initTemplate(templateId) {
    await this.listWaTemplates();
    if (this.waTemplates) {
      const template = this.waTemplates.find(wat => wat.id === templateId);
      if (template) {
        this.onSelectTemplate.emit(template);
      }
    }
  }

  ngOnInit(): void {
    this.listWaTemplates();
  }

  async listWaTemplates() {
    if (!this.selectedChannel) return;

    this.channelService.getTemplatesByChannel(this.selectedChannel._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async (templateMessageObjectPager: TemplateMessageObjectPager) => {

          try {
            const allTemplates = templateMessageObjectPager.data || [];

            if (this.selectedTemplateId) {
              const selectedTemplate = allTemplates.find(template => template.id === this.selectedTemplateId);
              if (selectedTemplate) {
                this.waTemplates = [selectedTemplate];
                this.allTemplates = [selectedTemplate];
              }
            } else {
              this.waTemplates = allTemplates;
              this.allTemplates = allTemplates;
            }

            this.waTemplates.forEach(wat => {
              wat.headerComponent = wat.components.find(c => c.type === 'HEADER');
              wat.bodyComponent = wat.components.find(c => c.type === 'BODY');
              wat.footerComponent = wat.components.find(c => c.type === 'FOOTER');
              wat.buttonsComponent = wat.components.find(c => c.type === 'BUTTONS');
            });
          } catch (error) {
            console.error('Error fetching templates:', error);
            this.waTemplates = [];
            this.allTemplates = [];
          }

        }
      });
  }

  showTemplateDetails(template: Partial<WaTemplateResponse>) {
    this.selectedTemplateId = template.id;
    this.onSelectTemplate.emit(template);
  }

  setupForm() {
    this.form = this.formBuilder.group({});
  }

  selectVarContactName(index: number) {
    this.form.get(`var${index}`).setValue('Nome do Contato');
  }

  selectVarLinkChannel(index: number) {
    this.form.get(`var${index}`).setValue('Link canal Whatsapp');
  }

  selectVarOperatorName(index: number) {
    this.form.get(`var${index}`).setValue('Nome atendente');
  }

  selectVarDepartmentName(index: number) {
    this.form.get(`var${index}`).setValue('Departamento');
  }

  selectVarProtocol(index: number) {
    this.form.get(`var${index}`).setValue('Nº do protocolo');
  }
}
