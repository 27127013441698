import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, AttendanceService, Channel, ChannelService, Contact, ContactEditComponent, ContactService, FirebaseService, MessageService, UploadTypeEnum, UtilHelper, WaTemplateResponse } from 'lib-trend-core';
import { TemplateMessage } from '../wa-template-select/wa-template-select.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'wa-template-message',
  templateUrl: './wa-template-message.component.html',
  styleUrls: ['./wa-template-message.component.scss']
})
export class WaTemplateMessageComponent extends AbstractComponent implements OnInit {

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinnerTemplate$ = this.loadingSpinnerSubject.asObservable();

  uploadHeader: boolean = false;
  midiaHeader: any

  selectedTemplate: Partial<WaTemplateResponse>;
  selectedChannel: Channel;
  selectedContact: Contact;
  fileZero: any;
  fileZeroSrc: string;

  file: File;

  templateDataInitial: Partial<TemplateMessage>;
  public editableInputs: boolean = true;

  @Input() set template(template: Partial<WaTemplateResponse>) {
    this.selectedTemplate = template;
    this.extractTemplateVariables();
    this.setupForm();
    this.emitTemplateData();
    this.initFormValue();
  }

  @Input() set initTemplateData(templateData: Partial<TemplateMessage>) {
    this.templateDataInitial = templateData;
    this.initFormValue();
  }


  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
  }

  @Input() set contact(contact: Contact) {
    this.selectedContact = contact;
  }

  @Output() templateParams = new EventEmitter();

  public override templateVariableList = [];
  previewText: string;
  form: FormGroup;

  constructor(
    injector: Injector,
    public contactService: ContactService,
    public channelService: ChannelService,
    public messageService: MessageService,
    public attendanceService: AttendanceService,
    public trendCloudAPIService: TrendCloudAPIService,
    public firebaseService: FirebaseService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    //this.setupForm();
  }

  initFormValue() {
    if (!this.form || !this.templateDataInitial) return;

    if (!!this.templateDataInitial.params && this.templateDataInitial.params.length > 0) {
      this.templateDataInitial.params.forEach((param, index) => {
        if (this.form.get(`var${index + 1}`)) {
          this.form.get(`var${index + 1}`).setValue(param);
          this.updatePreviewText();
        }
      });
    }

    if (this.templateDataInitial?.imageFile) {
      this.fileZeroSrc = this.templateDataInitial?.imageFile;
    }
    this.emitTemplateData();
  }

  onSelectImagem(event) {
    const target = event.target;
    const files: FileList = target.files;
    this.file = files[0];

    let reader = new FileReader();
    reader.onload = async file => {
      if (this.file) {
        this.loadingSpinnerSubject.next(true);
        this.uploadHeader = true
        const contents = file.target;
        const base64 = UtilHelper.arrayBufferToBase64(contents.result);
        const dateMilisecond = new Date().getTime();
        const filename: string = dateMilisecond.toString();
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.MESSAGE).then((snapshot: { url: string }) => {
          this.loadingSpinnerSubject.next(false);
          this.midiaHeader = snapshot.url;
          this.form.patchValue({ image: snapshot.url });

          this.emitTemplateData();
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  protected setupForm(): void {
    this.form = new FormGroup({});

    if (this.templateVariableList.length > 0) {
      this.templateVariableList.forEach((variable, index) => {
        this.form.addControl(`var${index + 1}`, new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30)]));

        this.form.get(`var${index + 1}`).valueChanges.subscribe(data => {
          this.updatePreviewText();
        });

        this.form.valueChanges.subscribe(value => {
          this.emitTemplateData();
        });
      });
    } else {
      this.updatePreviewText();
      this.templateParams.emit({
        previewText: this.previewText,
        params: []
      });
    }
  }

  emitTemplateData() {
    const params = this.templateVariableList.map((_, idx) => this.form.get(`var${idx + 1}`).value);
    this.templateParams.emit({
      previewText: this.previewText,
      params: params,
      imageFile: this.midiaHeader
    });
  }

  protected updatePreviewText(): void {
    let preview = this.selectedTemplate?.bodyComponent?.text;
    if (!preview) {
      this.previewText = '';
      return;
    }
    this.templateVariableList.forEach((variable, idx) => {
      preview = preview.replace(`{{${idx + 1}}}`, this.form.get(`var${idx + 1}`).value || '');
    });
    this.previewText = preview;
  }

  extractTemplateVariables(): void {
    if (!this.selectedTemplate) return;

    this.previewText = this.selectedTemplate.bodyComponent.text;

    this.templateVariableList = [];
    for (let i = 1; i <= 5; i++) {
      if (this.previewText.includes(`{{${i}}}`)) {
        this.templateVariableList.push(`{{${i}}}`);
      }
    }

    this.setupForm();
  }

  selectVarGreeting(varNumber: number): void {
    const currentHour = new Date().getHours();
    let greeting = '';

    if (currentHour >= 0 && currentHour < 12) {
      greeting = 'Bom dia';
    } else if (currentHour >= 12 && currentHour < 18) {
      greeting = 'Boa tarde';
    } else {
      greeting = 'Boa noite';
    }

    this.form.get(`var${varNumber}`).setValue(greeting);
  }


  // VARIABLE
  selectVarContactLead(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue('{{NOME}}');
  }

  // VARIABLE FIXED
  selectVarContactName(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue(this.selectedContact.name);
  }

  selectVarDepartmentName(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue(this.getCurrentUserUser().company.name);
  }

  selectVarOperatorName(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue(this.getCurrentUserUser().name);
  }

  selectVarLinkChannel(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue('{{LINK_CHANNEL}}');
  }

  selectVarProtocol(varNumber: number): void {
    this.form.get(`var${varNumber}`).setValue(this.selectedChannel._id);
  }

  clearFile() {
    this.fileZero = null;
    this.fileZeroSrc = null;
  }
}
